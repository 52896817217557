
// @ts-nocheck


export const localeCodes =  [
  "it",
  "en",
  "de",
  "nl"
]

export const localeLoaders = {
  "it": [{ key: "../lang/it.ts", load: () => import("../lang/it.ts" /* webpackChunkName: "locale__home_forge_vispauto_com_lang_it_ts" */), cache: true }],
  "en": [{ key: "../lang/en.ts", load: () => import("../lang/en.ts" /* webpackChunkName: "locale__home_forge_vispauto_com_lang_en_ts" */), cache: true }],
  "de": [{ key: "../lang/de.ts", load: () => import("../lang/de.ts" /* webpackChunkName: "locale__home_forge_vispauto_com_lang_de_ts" */), cache: true }],
  "nl": [{ key: "../lang/nl.ts", load: () => import("../lang/nl.ts" /* webpackChunkName: "locale__home_forge_vispauto_com_lang_nl_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "lang": "it",
      "code": "it",
      "iso": "it-IT",
      "dir": "ltr",
      "files": [
        "lang/it.ts"
      ]
    },
    {
      "lang": "en",
      "code": "en",
      "iso": "en-US",
      "dir": "ltr",
      "files": [
        "lang/en.ts"
      ]
    },
    {
      "lang": "de",
      "code": "de",
      "iso": "de-DE",
      "dir": "ltr",
      "files": [
        "lang/de.ts"
      ]
    },
    {
      "lang": "nl",
      "code": "nl",
      "iso": "nl-NL",
      "dir": "ltr",
      "files": [
        "lang/nl.ts"
      ]
    }
  ],
  "defaultLocale": "it",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "book/[branch]": {
      "it": "/prenota/[branch]",
      "en": "/book/[branch]",
      "de": "/buchen/[branch]",
      "nl": "/boeken/[branch]"
    },
    "checkout/success": {
      "it": "/acquisto/successo",
      "en": "/purchase/success",
      "de": "/kauf/erfolg",
      "nl": "/aankoop/succes"
    },
    "checkout/cancel": {
      "it": "/acquisto/fallimento",
      "en": "/purchase/cancel",
      "de": "/kauf/abbrechen",
      "nl": "/aankoop/annuleren"
    },
    "our-scooters": {
      "it": "/i-nostri-scooters",
      "en": "/our-scooters",
      "de": "/unsere-scooter",
      "nl": "/onze-scooters"
    },
    "who-we-are": {
      "it": "/chi-siamo",
      "en": "/who-we-are",
      "de": "/wer-wir-sind",
      "nl": "/wie-we-zijn"
    },
    "profile/edit/[encryptedCustomer]": {
      "it": "/profilo/modifica/[encryptedCustomer]",
      "en": "/profile/edit/[encryptedCustomer]",
      "de": "/profil/bearbeiten/[encryptedCustomer]",
      "nl": "/profiel/bewerken/[encryptedCustomer]"
    },
    "terms-and-conditions": {
      "it": "/termini-e-condizioni",
      "en": "/terms-and-conditions",
      "de": "/allgemeine-geschaftsbedingungen",
      "nl": "/algemene-voorwaarden"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "lang": "it",
    "code": "it",
    "iso": "it-IT",
    "dir": "ltr",
    "files": [
      {
        "path": "lang/it.ts",
        "cache": true
      }
    ]
  },
  {
    "lang": "en",
    "code": "en",
    "iso": "en-US",
    "dir": "ltr",
    "files": [
      {
        "path": "lang/en.ts",
        "cache": true
      }
    ]
  },
  {
    "lang": "de",
    "code": "de",
    "iso": "de-DE",
    "dir": "ltr",
    "files": [
      {
        "path": "lang/de.ts",
        "cache": true
      }
    ]
  },
  {
    "lang": "nl",
    "code": "nl",
    "iso": "nl-NL",
    "dir": "ltr",
    "files": [
      {
        "path": "lang/nl.ts",
        "cache": true
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
